import { api } from ":mods";
import { ENDPOINTS } from "../../const";

export async function postTaskDetails(id:string|number,data, isForm=true) {
  const multipart =  {
    "Content-Type": "multipart/form-data",
  }   
  return api
        .postAuth(ENDPOINTS.material_details_with_material_id_student + id, data, {
          headers: isForm ? multipart: {},
        })
        .then(async (res) => {
          return Promise.resolve(res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });

    }